import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import { MetadataReadonly } from "../types/contentfulTypes"
import { themes } from "../components/styles/ColorStyles"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
  }
}

const Terms = (props: Props) => {
  const { data } = props

  return (
    <Layout {...data}>
      <Wrapper>
        <Content>
          <h1>Terms of Service</h1>
          <p>
            <em>Last updated: October 07, 2019</em>
          </p>
          <p>
            These Terms of Service ("Terms", "Terms of Service") govern your relationship with{" "}
            <a href="https://angle.sh" aria-label="Terms">
              https://angle.sh
            </a>{" "}
            website operated by Shadowness Inc. ("us", "we", or "our").
          </p>
          <p>Please read these Terms of Service carefully before using our website.</p>
          <p>
            Your access to and use of the Service is based on your acceptance of and compliance with
            these Terms. These Terms apply to all visitors, users and others who access or use the
            Service.
          </p>
          <p>
            By accessing or using the Service you agree to be bound by these Terms and accept all
            legal consequences. If you do not agree to these terms and conditions, in whole or in
            part, please do not use the Service.
          </p>
          <h2>Purchases</h2>
          <p>
            If you wish to purchase any product or service made available through the Service
            ("Purchase"), you may be asked to supply certain information relevant to your Purchase
            including, without limitation, your credit card number, the expiration date of your
            credit card, your billing address, and your shipping information.
          </p>
          <p>
            You represent and warrant that: (i) you have the legal right to use any credit card(s)
            or other payment method(s) in connection with any Purchase; and that (ii) the
            information you supply to us is true, correct and complete.
          </p>
          <p>
            By submitting such information, you grant us the right to provide the information to
            third parties for purposes of facilitating the completion of Purchases.
          </p>
          <p>
            We reserve the right to refuse or cancel your order at any time for certain reasons
            including but not limited to: product or service availability, errors in the description
            or price of the product or service, error in your order or other reasons.
          </p>
          <p>
            We reserve the right to refuse or cancel your order if fraud or an unauthorised or
            illegal transaction is suspected. We will not be held responsible or liable for any
            failure for the Purchase to complete, or any resulting loss or damages to you.
          </p>
          <h2>Availability, Errors and Inaccuracies</h2>
          <p>
            In order to provide exceptional service, and accuracy, we regularly update the products
            and services on the Service.
          </p>
          <p>
            We cannot and do not guarantee the accuracy or completeness of any information,
            including prices, product images, specifications, availability, and services. We reserve
            the right to change or update information and to correct errors, inaccuracies, or
            omissions at any time without prior notice.
          </p>
          <p>
            Despite our best efforts, the products or services available on our Service may have an
            error regarding the price, be inaccurately described, or be unavailable.
          </p>
          <p>
            We may experience delays in updating information on the Service and in our advertising
            on other websites.
          </p>
          <h2>Contests, Sweepstakes and Promotions</h2>
          <p>
            Any contests, sweepstakes or other promotions (collectively, "Promotions") made
            available through the Service may be governed by rules that are separate from these
            Terms. If you participate in any Promotions, please review the applicable rules as well
            as our Privacy Policy. If the rules for a Promotion conflict with these Terms, the
            Promotion rules will apply.
          </p>
          <p>Promotions may be available for a limited time.</p>
          <h2>Subscriptions</h2>
          <p>
            Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You
            will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing
            cycles are set either on a monthly or annual basis, depending on the type of
            subscription plan you select when purchasing a Subscription.
          </p>
          <p>
            At the end of each Billing Cycle, your Subscription will automatically renew under the
            exact same conditions unless you cancel it or Shadowness Inc. cancels it. You may cancel
            your Subscription renewal either through your online account management page or by
            contacting Shadowness Inc. customer support team.
          </p>
          <p>
            A valid payment method is required to process the payment for your Subscription. You
            shall provide Shadowness Inc. with accurate and complete billing information including
            full name, address, state, zip code, telephone number, and a valid payment method
            information. By submitting such payment information, you automatically authorize
            Shadowness Inc. to charge all Subscription fees incurred through your account to any
            such payment instruments.
          </p>
          <p>
            Should automatic billing fail to occur for any reason, Shadowness Inc. will issue an
            electronic invoice indicating that you must proceed manually, within a certain deadline
            date, with the full payment corresponding to the billing period as indicated on the
            invoice.
          </p>
          <h2>Angle Assets</h2>
          <p>
            With a free account, you may only use the Angle assets for your personal projects. With
            a Pro account, you may use the Angle assets for your personal and commercial projects.
            You may not redistribute or resell.
          </p>
          <h2>Free Trial</h2>
          <p>
            Shadowness Inc. may, at its sole discretion, offer a Subscription with a free trial for
            a limited period of time ("Free Trial").
          </p>
          <p>
            You may be required to enter your billing information in order to sign up for the Free
            Trial.
          </p>
          <p>
            If you do enter your billing information when signing up for the Free Trial, you will
            not be charged by Shadowness Inc. until the Free Trial has expired. On the last day of
            the Free Trial period, unless you cancelled your Subscription, you will be automatically
            charged the applicable Subscription fees for the type of Subscription you have selected.
          </p>
          <p>
            At any time and without notice, Shadowness Inc. reserves the right to (i) modify the
            terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.
          </p>
          <h2>Fee Changes</h2>
          <p>
            Shadowness Inc., in its sole discretion and at any time, may modify the Subscription
            fees for the Subscriptions. Any Subscription fee change will become effective at the end
            of the then-current Billing Cycle.
          </p>
          <p>
            Shadowness Inc. will provide you with a reasonable prior notice of any change in
            Subscription fees to give you an opportunity to terminate your Subscription before such
            change becomes effective.
          </p>
          <p>
            Your continued use of the Service after the Subscription fee change comes into effect
            constitutes your agreement to pay the modified Subscription fee amount.
          </p>
          <h2>Refunds</h2>
          <p>
            Certain refund requests for Subscriptions may be considered by Shadowness Inc. on a
            case-by-case basis and granted in sole discretion of Shadowness Inc..
          </p>
          <h2>Accounts</h2>
          <p>
            When you create an account with us, you must provide us information that is accurate,
            complete, and current at all times. Failure to do so constitutes a breach of the Terms,
            which may result in immediate termination of your account on our Service.
          </p>
          <p>
            You are responsible for safeguarding the password that you use to access the Service and
            for any activities or actions under your password, whether your password is with our
            Service or a third-party service.
          </p>
          <p>
            You agree not to disclose your password to any third party. You must notify us
            immediately upon becoming aware of any breach of security or unauthorized use of your
            account.
          </p>
          <h2>Intellectual Property</h2>
          <p>
            The Service and all contents, including but not limited to text, images, graphics or
            code are the property of Shadowness Inc. and are protected by copyright, trademarks,
            database and other intellectual property rights. You may display and copy, download or
            print portions of the material from the different areas of the Service only for your own
            non-commercial use, or to place an order with Shadowness Inc.. Any other use is strictly
            prohibited and may violate copyright, trademark and other laws. These Terms do not grant
            you a license to use any trademark of Shadowness Inc. or its affiliates. You further
            agree not to use, change or delete any proprietary notices from materials downloaded
            from the Service.
          </p>
          <h2>Links To Other Web Sites</h2>
          <p>
            The Service may contain links to third-party web sites or services that are not owned or
            controlled by Shadowness Inc..
          </p>
          <p>
            Shadowness Inc. has no control over, and assumes no responsibility for, the content,
            privacy policies, or practices of any third party web sites or services. You further
            acknowledge and agree that Shadowness Inc. shall not be responsible or liable, directly
            or indirectly, for any damage or loss caused or alleged to be caused by or in connection
            with use of or reliance on any such content, goods or services available on or through
            any such websites or services.
          </p>
          <p>
            We strongly advise you to read the terms and conditions and privacy policies of any
            third-party web sites or services that you visit.
          </p>
          <h2>Termination</h2>
          <p>
            We may terminate or suspend access to our Service immediately, without prior notice or
            liability, for any reason whatsoever, including, without limitation, if you breach the
            Terms.
          </p>
          <p>
            All provisions of the Terms shall survive termination, including, without limitation,
            ownership provisions, warranty disclaimers, indemnity and limitations of liability.
          </p>
          <p>
            Upon termination, your right to use the Service will immediately cease. If you wish to
            terminate your account, you may simply discontinue using the Service.
          </p>
          <h2>Indemnification</h2>
          <p>
            You agree to indemnify, defend and hold harmless Shadowness Inc., its principals,
            officers, directors, representatives, employees, contractors, licensors, licensees,
            suppliers and agents, from and against any claims, losses, damages, losses, obligations,
            costs, actions or demands.
          </p>
          <p>
            These include but are not limited to: (a) legal and accounting fees resulting from your
            use of the Service; (b) your breach of any of these Terms; (c) anything you post on or
            upload to the Service; and (d) any activity related to your account. This includes any
            negligent or illegal conduct by you, any person or entity accessing the Service using
            your account whether such access is obtained via fraudulent or illegal means.
          </p>
          <h2>Limitation Of Liability</h2>
          <p>
            Shadowness Inc., its directors, employees, partners, agents, suppliers, or affiliates,
            shall not be liable for any loss or damage, direct or indirect, incidental, special,
            consequential or punitive damages, including without limitation, economic loss, loss or
            damage to electronic media or data, goodwill, or other intangible losses, resulting from
            (i) your access to or use of the Service; (ii) your inability to access or use the
            Service; (iii) any conduct or content of any third-party on or related to the Service;
            (iiv) any content obtained from or through the Service; and (v) the unauthorized access
            to, use of or alteration of your transmissions or content, whether based on warranty,
            contract, tort (including negligence) or any other claim in law, whether or not we have
            been informed of the possibility of such damage, and even if a remedy set forth herein
            is found to have failed of its essential purpose.
          </p>
          <h2>Disclaimer And Non-Waiver of Rights</h2>
          <p>
            Shadowness Inc. makes no guarantees, representations or warranties of any kind as
            regards the website and associated technology. Any purportedly applicable warranties,
            terms and conditions are excluded, to the fullest extent permitted by law. Your use of
            the Service is at your sole risk. The Service is provided on an "AS IS" and "AS
            AVAILABLE" basis. The Service is provided without warranties of any kind, whether
            express or implied, including, but not limited to, implied warranties of
            merchantability, fitness for a particular purpose, non-infringement or course of
            performance, except as provided for under the laws of any province in Canada. In such
            cases, the provincial law shall apply to the extent necessary.
          </p>
          <p>
            Shadowness Inc. its subsidiaries, affiliates, and its licensors do not warrant that a
            {")"} the Service will function uninterrupted, secure or available at any particular
            time or location; b{")"} any errors or defects will be corrected; c{")"} the Service is
            free of viruses or other harmful components; or d{")"} the results of using the Service
            will meet your requirements.
          </p>
          <p>
            If you breach any of these Terms and Shadowness Inc. chooses not to immediately act, or
            chooses not to act at all, Shadowness Inc. will still be entitled to all rights and
            remedies at any later date, or in any other situation, where you breach these Terms.
            Shadowness Inc. does not waive any of its rights. Shadowness Inc. shall not be
            responsible for any purported breach of these Terms caused by circumstances beyond its
            control. A person who is not a party to these Terms shall have no rights of enforcement.
          </p>
          <p>
            You may not assign, sub-license or otherwise transfer any of your rights under these
            Terms.
          </p>
          <h2>Exclusions</h2>
          <p>
            As set out, above, some jurisdictions do not allow the exclusion of certain warranties
            or the exclusion or limitation of liability for consequential or incidental damages, so
            the limitations above may not apply to you. Provincial laws of Canada may apply to
            certain products and service provided.
          </p>
          <h2>Governing Law</h2>
          <p>
            These Terms shall be governed by, and interpreted and enforced in accordance with, the
            laws in the Province of Quebec and the laws of Canada, as applicable.
          </p>
          <p>
            If any provision of these Terms is held to be invalid or unenforceable by a court of
            competent jurisdiction, then any remaining provisions of these Terms will remain in
            effect. These Terms constitute the entire agreement between us regarding our Service,
            and supersede and replace any prior agreements, oral or otherwise, regarding the
            Service.
          </p>
          <h2>Changes</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any
            time. If a revision is material we will make reasonable efforts to provide at least 30
            days' notice prior to any new terms taking effect. What constitutes a material change
            will be determined at our sole discretion.
          </p>
          <p>
            By continuing to access or use our Service after those revisions become effective, you
            agree to be bound by the revised terms. If you do not agree to the new terms, in whole
            or in part, please stop using the website and the Service.
          </p>
          <h2>Contact Us</h2>
          <p>If you have any questions about these Terms, please contact us.</p>
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default Terms

export const query = graphql`
  query TermsQuery {
    contentfulMetadata(slug: { eq: "terms" }) {
      ...MetadataFragment
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: 752px;
  margin: 0 auto;
  padding: 104px 20px 0px;
  box-sizing: border-box;
`

const Content = styled.div`
  position: relative;
  max-width: 800px;
  margin: 100px auto;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  color: ${themes.light.text1};

  @media (max-width: 414px) {
    font-size: 17px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text1};
  }

  img,
  video {
    margin: 20px 0;
    width: 100%;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;
  }

  iframe {
    margin: 20px 0;
    width: 100%;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;
  }

  h2 {
    padding: 20px 0 0;
    font-size: 32px;
    line-height: 120%;
  }

  h3 {
    padding: 20px 0 0;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    opacity: 0.7;
  }

  h2,
  h3 {
    margin-top: 50px;
  }

  p {
    text-align: left;
    color: rgba(0, 0, 0, 0.8);

    @media (prefers-color-scheme: dark) {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  a {
    text-decoration: none;
    font-weight: 600;
    color: ${themes.light.primary};

    @media (prefers-color-scheme: dark) {
      color: ${themes.dark.secondary};
    }
  }

  blockquote {
    padding: 0 16px 0 50px;
    background-repeat: no-repeat;
    background-position: 14px 2px;
    p {
      font-size: 28px;
      font-weight: 500;
      line-height: 140%;
    }
  }

  pre {
    border-radius: 16px;
  }

  code {
    font-size: 17px;
    font-family: "SF Mono", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif;
    font-weight: 500;
  }

  ol {
    padding: 0;
  }

  @media (max-width: 740px) {
    font-size: 18px;
    padding: 0 30px;

    .Content {
      margin: 50px 20px;
    }
    p {
      font-size: 18px;
    }
    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 20px;
    }
    blockquote {
      margin: 0;
      p {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 414px) {
    padding: 0 20px;
    code {
      font-size: 15px;
    }
    blockquote {
      margin: 0;
      p {
        font-size: 17px;
      }
    }
  }
`
